<header id="header" class="alt">
  <a href="/"> <img src="../assets/images/header-logo.png" alt="" />
  </a>
  <nav id="nav">
    <ul>
      <li><a onclick="scrollToBenefits()">Benefits</a></li>
      <li><div class="button chunky-button btn-primary" onclick="scrollToSignup();">Sign Up</div></li>
    </ul>
  </nav>
</header>

<app-banner></app-banner>
<app-sign-up></app-sign-up>
<app-benefits></app-benefits>
<app-footer></app-footer>
