import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import {BenefitsComponent} from '../../benefits/benefits/benefits.component';
import {BannerComponent} from '../../banner/banner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    FooterComponent
  ],
  declarations: [FooterComponent]
})
export class FooterModule { }
