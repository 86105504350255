import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignUpComponent} from './sign-up.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {MyDatePickerModule} from 'mydatepicker';
import {NgxCaptchaModule} from 'ngx-captcha';
import {IntlTelInputModule} from '../intl-tel-input/intl-tel-input.module';
import {IntlCountryInputModule} from '../intl-country-input/intl-country-input.module';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgxCaptchaModule,
    MyDatePickerModule,
    IntlTelInputModule,
    IntlCountryInputModule
  ],
  exports: [
    SignUpComponent
  ],
  declarations: [SignUpComponent]
})
export class SignUpModule {
}
