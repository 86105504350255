import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {finalize} from 'rxjs/operators';
import {IMyDateModel, IMyDpOptions} from 'mydatepicker';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  apiUrl = environment.apiUrl;
  savingForm = false;
  showAllInputs = false;
  heading = 'Create Account';
  errorMessage = null;
  successMessage = null;

  // email verification
  emailVerificationMessage = null;
  emailVerified = false;

  // form data
  timezones = [];
  professions = [];
  specialties = [];
  race = [];
  religion = [];
  healthcareProfessional = false;

  siteKey = '6LeppRUUAAAAAHKcgZQ_aHwYW2UeoLwJs13_2qMn';

  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'yyyy-mm-dd',
  };

  constructor(private http: HttpClient) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '75e73c50-bebd-4841-bfaa-0367ddfaac08'
    })
  };

  ngOnInit() {

    this.http.get(this.apiUrl + '/api/ref-data/timezone', this.httpOptions)
      .subscribe((data: any[]) => this.timezones = data);
    this.http.get(this.apiUrl + '/api/ref-data/profession', this.httpOptions)
      .subscribe((data: any[]) => this.professions = data);
    this.http.get(this.apiUrl + '/api/ref-data/race', this.httpOptions)
      .subscribe((data: any[]) => this.race = data);
    this.http.get(this.apiUrl + '/api/ref-data/religion', this.httpOptions)
      .subscribe((data: any[]) => this.religion = data);
  }

  register(form) {

    const postData = form.value;
    // hack because this datepicker uses it own date format!!
    postData.dateOfBirth = postData.dateOfBirth.jsdate;
    // data2['g-recaptcha-response'] = vcRecaptchaService.getResponse();

    // hack 2, convert "" to null as angular pumps in emtpy strings
    function replacer(key, value) {
      if (value == null || value == '' || (Object.keys(value).length === 0 && value.constructor == Object) || value == {}) {
        return undefined;
      } else if (value && typeof value === 'object') {
        let isEmptyObject = true;
        Object.keys(value).forEach(key => {
          if (value[key] != null && value[key] != '') {
            isEmptyObject = isEmptyObject && false;
          }
        });
        if (isEmptyObject) {
          return undefined;
        } else {
          return value;
        }
      } else {
        return value;
      }
    }

    if (postData.homeAddress) {
      postData.homeAddress.type = 'RESIDENTIAL';
    }
    if (postData.workAddress) {
      postData.workAddress.type = 'PRIVATE_PRACTICE';
    }

    const dataFormatted = JSON.stringify(postData, replacer);

    this.savingForm = true;

    this.http.post(this.apiUrl + '/api/account', dataFormatted, this.httpOptions)
      .pipe(finalize(() => {
        this.savingForm = false;
        // vcRecaptchaService.reload($scope.widgetId);
      }))
      .subscribe(
        data => {
          this.errorMessage = null;
          this.heading = 'Account Created';
          this.successMessage = 'Please check your email for an activation link';
        },
        error => {
          this.successMessage = null;
          this.errorMessage = error.statusText;
        }
      );
  }

  onPasswordChange(form) {
    if (form.value.password !== form.value.confirmPassword) {
      form.controls['confirmPassword'].setErrors({'incorrect': true});
      form.controls['confirmPassword'].markAsTouched();
    } else {
      form.controls['confirmPassword'].setErrors(null);
    }
  }

  onEmailChange(form: NgForm) {

    if (form.controls.email.invalid) {
      return;
    }

    const postData = {email: form.value.email};

    this.http.post(this.apiUrl + '/api/account/verify-email', postData, this.httpOptions)
      .subscribe(
        data => {
          this.emailVerified = true;
          this.emailVerificationMessage = null;
          form.controls['email'].setErrors(null);
        },
        error => {
          this.emailVerified = false;
          switch (error.status) {
            case 0:
              this.emailVerificationMessage = error.statusText;
              break;
            case 409:
              this.emailVerificationMessage = 'Email Taken';
              break;
            default:
              this.emailVerificationMessage = 'Error verifying email';
          }
          form.controls['email'].setErrors({'incorrect': true});
          form.controls['email'].markAsTouched();
        }
      );
  }

  loadSpecialty(form: NgForm) {
    const professionId = form.value.profession.id;
    if (professionId) {
      this.http.get(this.apiUrl + '/api/ref-data/specialty/' + professionId, this.httpOptions)
        .subscribe((data: any[]) => this.specialties = data);
    }
  }

}
