import {Directive, ElementRef, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'country-select-js';

@Directive({
  selector: '[appIntlCountryInput]'
})
export class IntlCountryInputDirective implements OnInit {

  ngCountryInput: any;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.ngCountryInput = $(this.el.nativeElement);
    this.ngCountryInput.countrySelect({
      preferredCountries: ['us', 'gb', 'za']
    });
  }

}
