import {NgModule, ModuleWithProviders} from '@angular/core';
import {IntlCountryInputDirective} from './intl-country-input.directive';

@NgModule({
  declarations: [IntlCountryInputDirective],
  exports: [IntlCountryInputDirective],
})
export class IntlCountryInputModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IntlCountryInputModule,
      providers: []
    };
  }
}
