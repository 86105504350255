import {Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output} from '@angular/core';
import * as $ from 'jquery';
import 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[appIntlTelInput]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => IntlTelInputDirective), multi: true}
  ]
})
export class IntlTelInputDirective implements OnInit, Validator {

  @Input('ngTelInputOptions') ngTelInputOptions: any;
  @Input('required') ngRequired: any;
  @Output('hasError') hasError: EventEmitter<boolean> = new EventEmitter();
  @Output('ngTelOutput') ngTelOutput: EventEmitter<any> = new EventEmitter();
  @Output('countryChange') countryChange: EventEmitter<any> = new EventEmitter();
  @Output('intlTelInputObject') intlTelInputObject: EventEmitter<any> = new EventEmitter();
  ngTelInput: any;

  private abstractControl: AbstractControl;

  constructor(private el: ElementRef) {
  }

  validate(c: AbstractControl): { [key: string]: any } {
    this.abstractControl = c;

    if (this.ngRequired || this.ngTelInput.intlTelInput('getNumber').length > 0) {
      const isValid: boolean = this.isInputValid();

      if (isValid) {
        return null;
      } else {
        return {
          valid: false
        };
      }
    }
    return null;

  }

  ngOnInit(): void {
    this.ngTelInput = $(this.el.nativeElement);

    if (this.ngTelInputOptions) {
      this.ngTelInput.intlTelInput(this.ngTelInputOptions);
    } else {
      this.ngTelInput.intlTelInput({
        preferredCountries: ['us', 'gb', 'za']
      });
    }

    this.ngTelInput.on('countrychange', (e: any, countryData: any) => {
      this.countryChange.emit(countryData);
    });

    this.intlTelInputObject.emit(this.ngTelInput);
  }

  @HostListener('blur') onBlur() {
    const isInputValid: boolean = this.isInputValid();
    if (isInputValid) {
      this.abstractControl.setValue(this.ngTelInput.intlTelInput('getNumber'), {
        emitEvent: false
      });
    }
  }

  isInputValid(): boolean {
    return this.ngTelInput.intlTelInput('isValidNumber');
  }

}
