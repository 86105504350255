<!-- Footer -->
<footer id="footer">
  <div class="container">
    <ul class="copyright">
      <li>&copy; HealthJoiner {{year}}</li>
      <li><a href="/terms.html" target="_blank">Terms</a></li>
      <li><a href="/privacy.html" target="_blank">Privacy</a></li>
    </ul>
  </div>
</footer>
