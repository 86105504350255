import {NgModule, ModuleWithProviders, forwardRef} from '@angular/core';
import {IntlTelInputDirective} from './intl-tel-input.directive';

@NgModule({
  declarations: [IntlTelInputDirective],
  exports: [IntlTelInputDirective],
})
export class IntlTelInputModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IntlTelInputModule,
      providers: []
    };
  }
}
