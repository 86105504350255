<!-- BENEFITS -->
<section id="benefits" class="wrapper special">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="benefits-header">
          <i class="fa fa-user-plus fa-3x" aria-hidden="true"></i>
          <h2 class="benefits-title">For Patients</h2>
        </div>
        <div class="content">
          <p>Access to your own electronic health folder online</p>
          <ul>
            <li>Free</li>
            <li>Secure and confidential</li>
            <li>24/7</li>
          </ul>
          <p>Healthjoiner could save your life. Improved medical care with fewer medical errors. Less time spent
            explaining past medical history to every new doctor. Less paperwork.</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="benefits-header">
          <i class="fa fa-user-md fa-3x" aria-hidden="true"></i>
          <h2 class="benefits-title">For Medical Professionals</h2>
        </div>
        <div class="content">
          <ul>
            <li>Improved patient care</li>
            <li>Ease of use with more productivity</li>
            <li>Data integrity and security</li>
            <li>Less IT hassle : No software installation or upgrades needed; no servers to buy or maintain</li>
            <li>Reduced expenses</li>
            <li>Decreased medical errors</li>
            <li>Decreased paperwork and administration (i.e. more time spent with your patient)</li>
            <li>Immediate implementation</li>
            <li>Ever-expanding all-in-one features including overview, consultation notes, prescriptions, results</li>
            <li>In conclusion: time and value</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
