<section id="signup" class="registration">
  <div class="offset-lg-4 offset-md-4 offset-sm-4 col-lg-4 col-md-4 col-sm-4">
    <div class="align-center">
      <img alt="HealthJoiner" src="../../assets/images/header-logo.png" class="logo">
    </div>
    <div class="card" style="background-color: #eee;">
      <div class="card-body">
        <h3 class="center">{{heading}}</h3>
        <h5 *ngIf="errorMessage" class="alert alert-danger center">{{errorMessage}}</h5>
        <h5 *ngIf="successMessage" class="alert alert-success center">{{successMessage}}</h5>

        <form #f="ngForm" (ngSubmit)="f.form.valid && register(f)" novalidate *ngIf="!successMessage">
          <div class="form-group">
            <label for="firstName" class="col-form-label">First Name *</label>
            <input type="text" class="form-control form-control-sm" id="firstName" name="firstName"
                   ngModel #firstName="ngModel"
                   [ngClass]="{ 'is-invalid': firstName.invalid && (f.submitted || firstName.touched) }" required
                   (click)="showAllInputs = true" autocomplete="off"/>
            <div *ngIf="firstName.invalid && (f.submitted || firstName.touched)" class="invalid-feedback">
              <div>Required field</div>
            </div>
          </div>

          <div class="form-group">
            <label for="lastName" class="col-form-label">Last Name *</label>
            <input type="text" class="form-control form-control-sm" id="lastName" name="lastName"
                   ngModel #lastName="ngModel"
                   [ngClass]="{ 'is-invalid': lastName.invalid && (f.submitted || lastName.touched) }" required
                   (click)="showAllInputs = true" autocomplete="off"/>
            <div *ngIf="lastName.invalid && (f.submitted || lastName.touched)" class="invalid-feedback">
              <div>Required field</div>
            </div>
          </div>

          <div *ngIf="showAllInputs">
            <div class="form-group">
              <label for="gender" class="col-form-label">Gender *</label>
              <select id="gender" name="gender" class="form-control form-control-sm" ngModel #gender="ngModel"
                      [ngClass]="{ 'is-invalid': gender.invalid && (f.submitted || gender.touched) }" required>
                <option disabled value=""></option>
                <option value="FEMALE">Female</option>
                <option value="MALE">Male</option>
              </select>
              <div *ngIf="gender.invalid && (f.submitted || gender.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>

            <div class="form-group">
              <label for="dateOfBirth" class="col-form-label">Date of Birth *</label>
              <my-date-picker name="dateOfBirth" id="dateOfBirth"
                              [options]="myDatePickerOptions" ngModel #dateOfBirth="ngModel" required
                              [ngClass]="{ 'is-invalid': dateOfBirth.invalid && (f.submitted || dateOfBirth.touched) }"></my-date-picker>
              <div *ngIf="dateOfBirth.invalid && (f.submitted || dateOfBirth.touched)" class="invalid-feedback"  [ngClass]="{ 'd-block': country.invalid}">
                <div>Required field</div>
              </div>
            </div>

            <div class="form-group">
              <label for="email" class="col-form-label">Email *</label>
              <input type="email" id="email" name="email" ngModel #email="ngModel" required
                     class="form-control form-control-sm" [email]="true"
                     [ngClass]="{ 'is-invalid': email.invalid && (f.submitted || email.touched) }"
                     (ngModelChange)="onEmailChange(f)"/>
              <i *ngIf="emailVerified" class="validation-green-tick"></i>
              <div *ngIf="email.invalid && (f.submitted || email.touched)" class="invalid-feedback">
                <div *ngIf="emailVerificationMessage; else invalidEmail">{{emailVerificationMessage}}</div>
                <ng-template #invalidEmail>Invalid email</ng-template>
              </div>
            </div>

            <div class="form-group">
              <label for="password" class="col-form-label">Password *</label>
              <input type="password" id="password" name="password" ngModel #password="ngModel" required
                     class="form-control form-control-sm"
                     [ngClass]="{ 'is-invalid': password.invalid && (f.submitted || password.touched) }"
                     (ngModelChange)="onPasswordChange(f)"/>
              <div *ngIf="password.invalid && (f.submitted || password.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="col-form-label">Confirm Password *</label>
              <input type="password" id="confirmPassword" name="confirmPassword" ngModel #confirmPassword="ngModel"
                     required class="form-control form-control-sm"
                     [ngClass]="{ 'is-invalid': confirmPassword.invalid && (f.submitted || confirmPassword.touched) }"
                     (ngModelChange)="onPasswordChange(f)"/>
              <div *ngIf="confirmPassword.invalid && (f.submitted || confirmPassword.touched)" class="invalid-feedback">
                <div>Passwords do not match</div>
              </div>
            </div>

            <div class="form-group col-form-label">
              <label for="cellNumber" class="col-form-label">Mobile Number</label>{{cellNumber.value}}
              <input type="text" id="cellNumber" name="cellNumber" ngModel #cellNumber="ngModel" autocomplete="off"
                     class="form-control form-control-sm" appIntlTelInput
                     [ngClass]="{ 'is-invalid': cellNumber.invalid && (f.submitted || cellNumber.touched) }"/>
              <div *ngIf="cellNumber.invalid && (f.submitted || cellNumber.touched)" class="invalid-feedback">
                <div>Invalid number</div>
              </div>
            </div>

            <!-- Home address details -->
            <div ngModelGroup="homeAddress" #homeAddress="ngModelGroup">
              <div class="form-group">
                <label for="line1" class="col-form-label">Home Address *</label>
                <input id="line1" type="text" name="line1" placeholder="Street Address"
                       ngModel #line1="ngModel" class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line1.invalid && (f.submitted || line1.touched) }"/>
                <div *ngIf="line1.invalid && (f.submitted || line1.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="line2" placeholder="Suburb" ngModel #line2="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line2.invalid && (f.submitted || line2.touched) }"/>
                <div *ngIf="line2.invalid && (f.submitted || line2.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="line3" placeholder="City" ngModel #line3="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line3.invalid && (f.submitted || line3.touched) }"/>
                <div *ngIf="line3.invalid && (f.submitted || line3.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="line4" placeholder="State/Province" ngModel #line4="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line4.invalid && (f.submitted || line4.touched) }"/>
                <div *ngIf="line4.invalid && (f.submitted || line4.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="postcode" placeholder="Postcode" ngModel #postcode="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': postcode.invalid && (f.submitted || postcode.touched) }"/>
                <div *ngIf="postcode.invalid && (f.submitted || postcode.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="country" placeholder="Country" ngModel #country="ngModel"
                       class="form-control form-control-sm" required appIntlCountryInput
                       [ngClass]="{ 'is-invalid': country.invalid && (f.submitted || country.touched) }"/>
                <div *ngIf="country.invalid && (f.submitted || country.touched)" class="invalid-feedback" [ngClass]="{ 'd-block': country.invalid}">
                  <div>Required field</div>
                </div>
              </div>
            </div>


            <!-- Medical aid details -->
            <div class="form-group">
              <label for="medicalAidName" class="col-form-label">Medical Aid Details</label>
              <input type="text" id="medicalAidName" name="medicalAidName" placeholder="Medical Aid Name" ngModel
                     class="form-control form-control-sm"/>
            </div>
            <div class="form-group">
              <input type="text" id="medicalAidNumber" name="medicalAidNumber" placeholder="Medical Aid Number" ngModel
                     class="form-control form-control-sm"/>
            </div>
            <div class="form-group">
              <input type="text" id="medicalAidPlan" name="medicalAidPlan" placeholder="Medical Aid Plan" ngModel
                     class="form-control form-control-sm"/>
            </div>

            <!-- Other personal details -->
            <div class="form-group">
              <label for="cityOfBirth" class="col-form-label">City of Birth</label>
              <input type="text" id="cityOfBirth" name="cityOfBirth" ngModel class="form-control form-control-sm" emptyAsNull/>
            </div>
            <div class="form-group">
              <label for="countryOfBirth" class="col-form-label">Country of Birth</label>
              <input type="text" id="countryOfBirth" name="countryOfBirth" ngModel appIntlCountryInput
                     class="form-control form-control-sm"/>
            </div>
            <div class="form-group">
              <label for="firstLanguage" class="col-form-label">First Language</label>
              <input type="text" id="firstLanguage" name="firstLanguage" ngModel class="form-control form-control-sm"/>
            </div>
            <div class="form-group">
              <label for="maritalStatus" class="col-form-label">Marital Status</label>
              <select id="maritalStatus" name="maritalStatus" class="form-control form-control-sm" ngModel
                      #maritalStatus="ngModel">
                <option disabled value=""></option>
                <option value="SINGLE">Single</option>
                <option value="MARRIED">Married</option>
                <option value="DIVORCED">Divorced</option>
                <option value="WIDOWED">Widowed</option>
              </select>
            </div>

            <div class="form-group">
              <label for="race" class="col-form-label">Race</label>
              <select id="race" class="form-control form-control-sm" name="race">
                <option disabled value=""></option>
                <option [ngValue]="r.key" *ngFor="let r of race | keyvalue">{{r.value}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="religion" class="col-form-label">Religion</label>
              <select id="religion" class="form-control form-control-sm" name="religion">
                <option disabled value=""></option>
                <option [ngValue]="r.key" *ngFor="let r of religion | keyvalue">{{r.value}}</option>
              </select>
            </div>

            <div class="form-group">
              <label for="occupation" class="col-form-label">Occupation</label>
              <input type="text" id="occupation" name="occupation" ngModel class="form-control form-control-sm"/>
            </div>
            <div class="form-group">
              <label for="employer" class="col-form-label">Employer</label>
              <input type="text" name="employer" ngModel class="form-control form-control-sm" id="employer"/>
            </div>

            <div class="form-group">
              <label for="emergencyContactName" class="col-form-label">Emergency Contact Details</label>
              <input type="text" name="emergencyContactName" placeholder="Contact Name" ngModel
                     class="form-control form-control-sm" id="emergencyContactName"/>
            </div>
            <div class="form-group">
              <input type="text" id="emergencyContactNumber" name="emergencyContactNumber" ngModel #emergencyContactNumber="ngModel" autocomplete="off"
                     class="form-control form-control-sm" appIntlTelInput placeholder="Contact Number"
                     [ngClass]="{ 'is-invalid': emergencyContactNumber.invalid && (f.submitted || emergencyContactNumber.touched) }"/>
              <div *ngIf="emergencyContactNumber.invalid && (f.submitted || emergencyContactNumber.touched)" class="invalid-feedback" [ngClass]="{ 'd-block': country.invalid}">
                <div>Invalid number</div>
              </div>
            </div>

            <div class="form-group">
              <div><label class="col-form-label">Are you a healthcare professional? *</label></div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="no" name="healthcareProfessional" [value]="false"
                       [(ngModel)]="healthcareProfessional" >
                <label class="form-check-label" for="no">No</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="healthcareProfessional" id="yes" [value]="true"
                       [(ngModel)]="healthcareProfessional" >
                <label class="form-check-label" for="yes">Yes</label>
              </div>
            </div>

          </div> <!-- end initially hidden content -->


          <!--health care professional-->
          <div *ngIf="f.value.healthcareProfessional === true">
            <div class="form-group">
              <label for="title" class="col-form-label">Title *</label>
              <input type="text" class="form-control form-control-sm" name="title" id="title" ngModel #title="ngModel"
                     [ngClass]="{ 'is-invalid': title.invalid && (f.submitted || title.touched) }" required
                     autocomplete="off"/>
              <div *ngIf="title.invalid && (f.submitted || title.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>

            <div class="form-group">
              <label for="profession" class="col-form-label">Profession *</label>
              <select id="profession" class="form-control form-control-sm" ngModel #profession="ngModel"
                      [ngClass]="{ 'is-invalid': profession.invalid && (f.submitted || profession.touched) }"
                      name="profession" required (change)="loadSpecialty(f)">
                <option disabled value=""></option>
                <option *ngFor="let prof of professions" [ngValue]="prof">{{prof.value}}</option>
              </select>
              <div *ngIf="profession.invalid && (f.submitted || profession.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>

            <div class="form-group">
              <label for="specialty" class="col-form-label">Specialty *</label>
              <select id="specialty" class="form-control form-control-sm" ngModel #specialty="ngModel" name="specialty"
                      required [ngClass]="{ 'is-invalid': specialty.invalid && (f.submitted || specialty.touched) }">
                <option disabled value=""></option>
                <option *ngFor="let special of specialties" [ngValue]="special">{{special.name}}</option>
              </select>
              <div *ngIf="specialty.invalid && (f.submitted || specialty.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>

            <div class="form-group">
              <label for="qualifications" class="col-form-label">Qualifications *</label>
              <input type="text" name="qualifications" required ngModel #qualifications="ngModel"
                     [ngClass]="{ 'is-invalid': qualifications.invalid && (f.submitted || qualifications.touched) }"
                     class="form-control form-control-sm" id="qualifications"/>
              <div *ngIf="qualifications.invalid && (f.submitted || qualifications.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>

            <div class="form-group">
              <label for="primaryRegistrationNumber" class="col-form-label">Primary Registration Numbers</label>
              <input type="text" name="primaryRegistrationNumber" ngModel #primaryRegistrationNumber="ngModel"
                     class="form-control form-control-sm" id="primaryRegistrationNumber"/>
            </div>
            <div class="form-group">
              <label for="secondaryRegistrationNumber" class="col-form-label">Other Registration Numbers</label>
              <input type="text" name="secondaryRegistrationNumber" ngModel #secondaryRegistrationNumber="ngModel"
                     class="form-control form-control-sm" id="secondaryRegistrationNumber"/>
            </div>

            <!-- Work address details -->
            <div ngModelGroup="workAddress">
              <div class="form-group">
                <label class="col-form-label">Work Address *</label>
                <input type="text" name="line1" placeholder="Street Address" ngModel #line1="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line1.invalid && (f.submitted || line1.touched) }"/>
                <div *ngIf="line1.invalid && (f.submitted || line1.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="line2" placeholder="Suburb" ngModel #line2="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line2.invalid && (f.submitted || line2.touched) }"/>
                <div *ngIf="line2.invalid && (f.submitted || line2.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="line3" placeholder="City" ngModel #line3="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line3.invalid && (f.submitted || line3.touched) }"/>
                <div *ngIf="line3.invalid && (f.submitted || line3.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="line4" placeholder="State/Province" ngModel #line4="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': line4.invalid && (f.submitted || line4.touched) }"/>
                <div *ngIf="line4.invalid && (f.submitted || line4.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="postcode" placeholder="Postcode" ngModel #postcode="ngModel"
                       class="form-control form-control-sm" required
                       [ngClass]="{ 'is-invalid': postcode.invalid && (f.submitted || postcode.touched) }"/>
                <div *ngIf="postcode.invalid && (f.submitted || postcode.touched)" class="invalid-feedback">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" name="country" placeholder="Country" ngModel #country="ngModel"
                       class="form-control form-control-sm" required appIntlCountryInput
                       [ngClass]="{ 'is-invalid': country.invalid && (f.submitted || country.touched) }"/>
                <div *ngIf="country.invalid && (f.submitted || country.touched)" class="invalid-feedback" [ngClass]="{ 'd-block': country.invalid}">
                  <div>Required field</div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label" for="telephone">Work Telephone *</label>
                <input type="text" id="telephone" name="telephone" ngModel #telephone="ngModel"
                       class="form-control form-control-sm" required appIntlTelInput
                       [ngClass]="{ 'is-invalid': telephone.invalid && (f.submitted || telephone.touched) }"/>
                <div *ngIf="telephone.invalid && (f.submitted || telephone.touched)" class="invalid-feedback" [ngClass]="{ 'd-block': country.invalid}">
                  <div>Invalid number</div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="timezone" class="col-form-label">Time Zone *</label>
              <select id="timezone" class="form-control form-control-sm" ngModel #timezone="ngModel" name="timezone"
                      required [ngClass]="{ 'is-invalid': timezone.invalid && (f.submitted || timezone.touched) }">
                <option disabled value=""></option>
                <option *ngFor="let zone of timezones" [ngValue]="zone">{{zone.description}}</option>
              </select>
              <div *ngIf="timezone.invalid && (f.submitted || timezone.touched)" class="invalid-feedback">
                <div>Required field</div>
              </div>
            </div>

          </div> <!-- end provider hidden fields -->


          <div class="form-group" style="width: 304px; margin-right: auto; margin-left: auto;">
          <!--<div required vc-recaptcha key="ctrl.publicKey" on-success="ctrl.setResponse(response)"></div>-->
            <ngx-recaptcha2 [siteKey]="siteKey" name="recaptcha" ngModel #recaptcha="ngModel" required>
            </ngx-recaptcha2>
          </div>




          <div class="form-group row center">
            <div class="col-sm-12 col-md-12">
              <button type="submit" [disabled]="savingForm" class="btn btn-primary">Create Account</button>
              <div class="btn-spinner" id="spinner-20 btn-spinner" data-ng-show="ctrl.savingForm"
                   data-ng-include="'spinner-templates/10-fading-circles.html'"></div>
            </div>
          </div>
        </form>
        <div class="form-group">
          <div class="center align-center">
            By creating an account, you agree to Healthjoiner’s <a href="/terms.html" target="_blank">Terms of Service</a> and
            <a
              href="/privacy.html" target="_blank">Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
