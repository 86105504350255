import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BannerModule} from './banner/banner.module';
import {SignUpModule} from './sign-up/sign-up.module';
import {BenefitsModule} from './benefits/benefits/benefits.module';
import {FooterModule} from './footer/footer/footer.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BannerModule,
    SignUpModule,
    BenefitsModule,
    FooterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
